// src/components/HappyPatients.js
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const stats = [
    { label: 'Happy People', value: '50+' },
    { label: 'Surgery Completed', value: '100+' },
    { label: 'Expert Doctors', value: '5' },
];

const HappyPatients = () => {
    return (
        <Box sx={{ py: 5, backgroundColor: '#f0f4f8', textAlign: 'center' }} id="happy-patients">
            <Typography variant="h4" gutterBottom>
                Award Winning Patient Care
            </Typography>
            <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
                {stats.map((stat, index) => (
                    <Grid item xs={6} md={3} key={index}>
                        <Typography variant="h3" color="primary">
                            {stat.value}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {stat.label}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default HappyPatients;

import React, { useEffect } from "react";
import { Typography, Box, Grid, Paper } from "@mui/material";
import SupportIcon from "@mui/icons-material/SupportAgent";
import PaymentIcon from "@mui/icons-material/Payment";
import ClaimIcon from "@mui/icons-material/AssignmentTurnedIn";
import InvoiceIcon from "@mui/icons-material/Description";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { services } from "../constants/services";
import { useParams } from "react-router-dom";

const ServiceDetails = () => {
  const params = useParams();
  const [service, setService] = React.useState(null);

  useEffect(() => {
    const serviceData = services.find((service) => service.id === params.type);
    setService(serviceData);
  }, [params.type]);

  if (!service) {
    return null;
  }

  return (
    <Box sx={{ maxWidth: "100%" }}>
      {/* Hero Section */}
      <Box
        sx={{
          textAlign: "center",
          py: 6,
          backgroundImage: `url(../${service.banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          color: "#fff", // Primary text color
          // Optional overlay for readability
          "::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent dark overlay
            zIndex: 1,
          },
          zIndex: 2,
        }}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          gutterBottom
          sx={{
            position: "relative", // Ensures text is above the overlay
            zIndex: 2,
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)", // Dark shadow for better contrast
          }}
        >
          {service.heading}
        </Typography>
      </Box>

      {/* Why Choose Us Section */}
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{service.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{service.description}</Typography>
          </Grid>
        </Grid>
      </Box>

      {service?.serviceHeading ? <Box sx={{ py: 4, backgroundColor: "#f5f5f5", borderRadius: 2, px: 3 }}> <Typography variant="body1" fontWeight="bold" textAlign="center" gutterBottom>{service.serviceHeading}</Typography> </Box>: null}

      {service?.services?.length > 0 && <Box sx={{ py: 4, backgroundColor: "#f5f5f5", borderRadius: 2, px: 3 }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          textAlign="center"
          gutterBottom
        >
          {service.heading} services include:
        </Typography>
        <Grid container spacing={3}>
          {service.services.map((service, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight="bold">
                  {service}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>}
    </Box>
  );
};

export default ServiceDetails;

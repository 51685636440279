// TopBar.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const TopBar = () => (
  <Box sx={{ backgroundColor: '#2a5c82', color: '#fff', display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton color="inherit"><EmailIcon /></IconButton>
      <Typography variant="body2">support@csquare.com</Typography>
      <IconButton color="inherit"><LocationOnIcon /></IconButton>
      <Typography variant="body2">Address 159 Parer Road, Airport West, VIC, 3042</Typography>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body2">Call Now : 823-4565-13456</Typography>
    </Box>
  </Box>
);

export default TopBar;

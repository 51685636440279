const city = "Victoria";

export const services = [
  {
    id: "personal-and-self-care",
    heading: "Personal and self-care",
    thumbnail: "personal-care.jpg",
    banner: "personal-care-banner.jpg",
    title:
      "Our personal care services are delivered with respect and understanding which we believe are just as important as the practical skills and competencies.",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "support-for-daily-activities",
    heading: "Support for daily activities",
    title: "Whatever help you need around the house, we can do it.",
    thumbnail: "daily-support.jpg",
    banner: "daily-support-banner.jpg",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "domestic-assistance",
    heading: "Domestic assistance",
    title: "Whatever help you need around the house, we can do it.",
    banner: "domestic-assistance-banner.jpg",
    thumbnail: "domestic-assistance.jpg",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "social-and-community-participation-transition",
    heading: "Social and community participation, Transition",
    title: "Whatever help you need around the house, we can do it.",
    thumbnail: "social.jpg",
    banner: "social-banner.png",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "dementia-care-and-support",
    heading: "Dementia care and support",
    thumbnail: "dementia-care.jpg",
    banner: "dementia-care-banner.jpg",
    title: "Whatever help you need around the house, we can do it.",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "full-day-support",
    heading: "24-hour support",
    title: "Whatever help you need around the house, we can do it.",
    thumbnail: "24x7support.jpg",
    banner: "banner-24x7.jpg",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "companion-care",
    heading: "Companion care",
    title: "Whatever help you need around the house, we can do it.",
    banner: "companion-care-banner.jpg",
    thumbnail: "companion-care.jpg",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "in-home-respite",
    heading: "In-home respite",
    thumbnail: "in-home-care.jpg",
    banner: "in-home-care-banner.jpg",
    title:
      "Our personal care services are delivered with respect and understanding which we believe are just as important as the practical skills and competencies.",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re there to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "nursing-care",
    heading: "Nursing Care",
    banner: "nursing-care-banner.jpg",
    thumbnail: "nursing-care.jpg",
    title:
      "All our nurses are handpicked for their experience, and because they share our passion for working in the community.",
    description:
      "Whether you’re recovering from an injury, have a long-term illness or need support managing a chronic condition, in-home nursing gives you the clinical support you need to improve or maintain your health and wellbeing. We are also a registered NDIS provider with a team of dedicated registered nurses and enrolled nurses. Our team can provide home nursing services for NDIS participants, including people with complex care needs.",
    serviceHeading:
      "There are some care services which can only be provided by nurses. If you have an injury, illness, or care needs which require regular clinical attention, then in-home nursing care is the perfect way to rest and recover. Our nursing services include:",
      services: [
        "Wound care",
        "Medications & injections",
        "Cervical collar care",
        "Urinary catheters",
        "Removal of staples and sutures",
        "PEG feeding",
      ],
    icon: "❤️",
  },
  {
    id: "palliative-care",
    heading: "Palliative Care",
    banner: "palliative-care.jpg",
    thumbnail: "palliative-care-banner.jpg",
    title: "All our nurses are handpicked for their experience, and because they share our passion for working in the community.",
    description: "Whether you’re recovering from an injury, have a long-term illness or need support managing a chronic condition, in-home nursing gives you the clinical support you need to improve or maintain your health and wellbeing. We are also a registered NDIS provider with a team of dedicated registered nurses and enrolled nurses. Our team can provide home nursing services for NDIS participants, including people with complex care needs.",
    serviceHeading: "There are some care services which can only be provided by nurses. If you have an injury, illness, or care needs which require regular clinical attention, then in-home nursing care is the perfect way to rest and recover. Our nursing services include:",
    services: [
      "Wound care",
      "Medications & injections",
      "Cervical collar care",
      "Urinary catheters",
      "Removal of staples and sutures",
      "PEG feeding",
    ],
    icon: "❤️",
  }
];
